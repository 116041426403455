import { all, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { exerciseActionTypes } from './Constants';
import { getUserId } from './Selectors';
import { getPlayListSections } from '../report/Selectors';
import * as sharedActions from '../../shared/Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';
import * as exerciseActions from './Actions';
import * as learningPathActions from '../learningPath/Actions';
import { getSteps } from '../learningPath/Selectors';
import { checkDailyGoal } from '../learningPath/Helper';
import { REACT_APP_BUILD_NUMBER } from '../../services/Constants';

export default function* () {
	yield all([
		yield takeLatest(
			exerciseActionTypes.GET_QUESTION_EXPLANATION,
			handleQuestionExplanationRequest,
		),
		yield takeLatest(exerciseActionTypes.GET_EXERCISE_REQUEST, handleGetExerciseRequest),
		yield takeLatest(
			exerciseActionTypes.GET_EXERCISE_REPORT_REQUEST,
			handleGetExerciseReportRequest,
		),
		yield takeLatest(exerciseActionTypes.SEND_QUESTION_ANSWER_REQUEST, handleSendAnswerRequest),
		yield takeLatest(
			exerciseActionTypes.SEND_TOPIC_INSTRUCTION_FEEDBACK,
			handleSendTopicInstructionFeedback,
		),
		yield takeLatest(
			exerciseActionTypes.SEND_QUESTION_INSTRUCTION_FEEDBACK,
			handleSendQuestionInstructionFeedback,
		),
		yield takeLatest(exerciseActionTypes.STORE_USER_QUESTION_TIME, handleStoreTime),
		yield takeLatest(
			exerciseActionTypes.SAVE_AUDIO_PREFERENCES_REQUEST,
			handleSaveAudioPreferencesRequest,
		),
		yield takeLatest(
			exerciseActionTypes.FINISH_LEARNING_PATH_SECTION_REQUEST,
			handleFinishLearningPathSectionRequest,
		),
	]);
}

export function* handleQuestionExplanationRequest(action) {
	try {
		const { questionId, exerciseId } = action.payload;

		const { data } = yield call(processRequest, `questions/${questionId}/explanation`, 'GET', {
			exercise_id: exerciseId,
			object_id: questionId,
		});

		yield put(
			exerciseActions.getQuestionExplanationSuccess({
				explanation: data.explanation,
			}),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleGetExerciseRequest({ payload = {} }) {
	try {
		const { id } = payload;
		const { data, headers } = yield call(processRequest, `user_learning_path_sections/${id}`);
		if (
			headers['x-current-client-version'] &&
			REACT_APP_BUILD_NUMBER &&
			headers['x-current-client-version'] !== REACT_APP_BUILD_NUMBER
		) {
			window.location.reload();
		}

		yield put(exerciseActions.getExerciseSuccess(data.user_learning_path_section));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(exerciseActions.getExerciseError());
	}
}

export function* handleGetExerciseReportRequest({ payload = {} }) {
	try {
		const { id } = payload;
		const playListSections = yield select(getPlayListSections);

		if (playListSections[id]) {
			yield put(exerciseActions.getExerciseReportSuccess(playListSections[id]));
			return;
		}

		const { data } = yield call(processRequest, `user_learning_path_sections/${id}`, 'GET', {
			preview: true,
		});

		yield put(exerciseActions.getExerciseReportSuccess(data.user_learning_path_section));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(exerciseActions.getExerciseReportError());
	}
}

export function* handleSendAnswerRequest({ payload }) {
	try {
		const { playlistId, data, lastQuestion, callback } = payload || {};

		const response = yield call(processRequest, `playlist_section_answers`, 'POST', data);

		// yield delay(3000);

		yield put(exerciseActions.sendAnswerSuccess());

		callback && callback(response.data);

		if (lastQuestion) {
			yield put(exerciseActions.finishLearningPathSectionRequest(playlistId));
		}
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(exerciseActions.sendAnswerError());
	}
}

export function* handleSendTopicInstructionFeedback({ payload }) {
	const { isUseful, instructionId, questionId } = payload || {};
	try {
		const instruction_feedback = {
			instruction_id: instructionId,
			question_id: questionId,
			feedback: isUseful,
		};
		yield call(processRequest, 'instruction_feedbacks', 'POST', instruction_feedback);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

export function* handleSendQuestionInstructionFeedback({ payload }) {
	const { feedback, questionId } = payload || {};
	try {
		const instruction_feedback = {
			question_id: questionId,
			feedback: feedback,
		};
		yield call(processRequest, 'instruction_feedbacks', 'POST', instruction_feedback);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

export function* handleStoreTime({ payload }) {
	const { answer_id, playtime_in_seconds } = payload || {};
	try {
		yield call(processRequest, `playlist_section_answers/${answer_id}`, 'PUT', {
			playtime_in_seconds,
		});
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

export function* handleSaveAudioPreferencesRequest({ payload }) {
	try {
		const userId = yield select(getUserId);
		const { audioOn, audioOnboarded } = payload;

		const data = { audio_on: audioOn };
		if (audioOnboarded !== undefined) {
			data.question_instruction_onboarded = audioOnboarded;
		}

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: data,
		});

		yield put(sharedActions.getUserDataRequest());

		yield put(exerciseActions.saveAudioPreferencesSuccess());
	} catch (e) {
		yield put(exerciseActions.saveAudioPreferencesError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleFinishLearningPathSectionRequest({ payload }) {
	const { playlistId } = payload || {};
	try {
		const steps = yield select(getSteps);
		const isDailyGoalTile = checkDailyGoal(playlistId, steps);

		// TODO: Check with backend if need still send daily_goal: true
		const { data } = yield call(
			processRequest,
			`user_learning_path_sections/${playlistId}/finish`,
			'POST',
			isDailyGoalTile ? { daily_goal: true } : null,
		);

		if (data.learning_path_updated) {
			yield put(learningPathActions.setLearningPathUpdated(true));
		}
		if (data.level_up && data.relative_difficulty) {
			yield put(exerciseActions.setLevelUp(data.relative_difficulty));
		}

		yield put(exerciseActions.finishLearningPathSectionSuccess());
		yield put(learningPathActions.setFinishTileAnimation(true));
	} catch (e) {
		yield put(exerciseActions.finishLearningPathSectionError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

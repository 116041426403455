import { exerciseActionTypes } from './Constants';
import { exerciseTypeCodesNext } from '../../services/Constants';
import { getScoreData } from './services/Helper';

const initialState = {
	audioOn: true,
	correctCount: 0,
	correctStreak: 0,
	currentQuestion: null,
	datePlayed: null,
	endscreen: null,
	exerciseTypes: {
		isDictateType: false,
		isOpenEndedType: false,
		isInYourMindType: false,
		isMultipleChoiceType: false,
		isTheBlanksType: false,
		isOpenMultipleType: false,
		isDictateMultipleType: false,
		isMultipleChoiceMultipleAnswersType: false,
	},
	explanation: {},
	id: null,
	incorrectCount: 0,
	instruction: {},
	instructionAudioOn: false,
	instructionFeedbacks: {},
	isReadingMode: false,
	levelUp: null,
	loading: false,
	loadingAnswer: false,
	loadingFinish: false,
	playlist_section_text: {},
	questions: [],
	questionsLeftCount: 0,
	readingProgress: 0,
	readingTextSize: 2,
	section_type: null,
	showInstructionHint: false,
	showPulsatingHighlight: false,
	showQuestionInstruction: false,
	showReadingTitle: false,
	showTopicInstruction: false,
	topic_subcategory_name: null,
	topic_title: null,
	viewMode: null,
};

export default function exerciseReducer(state = initialState, action) {
	const { type, payload } = action;
	const {
		audioOn,
		correctCount,
		correctStreak,
		currentQuestion,
		data,
		endscreen,
		exerciseType,
		incorrectCount,
		instructionAudioOn,
		instructionId,
		isReadingMode,
		isUseful,
		levelUp,
		questionId,
		questionsLeftCount,
		readingProgress,
		readingTextSize,
		showInstructionHint,
		showPulsatingHighlight,
		showQuestionInstruction,
		showReadingTitle,
		showTopicInstruction,
		viewMode,
	} = payload || {};

	switch (type) {
		case exerciseActionTypes.GET_QUESTION_EXPLANATION:
		case exerciseActionTypes.GET_EXERCISE_REQUEST:
		case exerciseActionTypes.GET_EXERCISE_REPORT_REQUEST:
			return {
				...state,
				loading: true,
			};

		case exerciseActionTypes.SEND_QUESTION_ANSWER_REQUEST:
			return {
				...state,
				loadingAnswer: true,
			};

		case exerciseActionTypes.SEND_QUESTION_ANSWER_SUCCESS:
		case exerciseActionTypes.SEND_QUESTION_ANSWER_ERROR:
			return {
				...state,
				loadingAnswer: false,
			};

		case exerciseActionTypes.SET_SELECTED_ITEMS:
		case exerciseActionTypes.GET_QUESTION_EXPLANATION_SUCCESS:
			return {
				...state,
				...data,
			};

		case exerciseActionTypes.GET_EXERCISE_SUCCESS:
			const { correct, incorrect, questionsLeft } = getScoreData(data);
			return {
				...state,
				...data,
				correctCount: correct,
				incorrectCount: incorrect,
				questionsLeftCount: questionsLeft.length,
			};

		case exerciseActionTypes.GET_EXERCISE_REPORT_SUCCESS:
			return {
				...state,
				...data,
				correctCount: data.correct_answers_count,
				incorrectCount: data.wrong_answers_count,
				datePlayed: data.date_played,
			};

		case exerciseActionTypes.CLEAR_STATE:
			return {
				...state,
				...initialState,
			};

		case exerciseActionTypes.CLEAR_EXPLANATION:
			return {
				...state,
				explanation: {},
			};

		case exerciseActionTypes.SET_EXERCISE_SCORE:
			return {
				...state,
				correctCount,
				incorrectCount,
				questionsLeftCount,
			};

		case exerciseActionTypes.HANDLE_NEXT_EXERCISE_TYPES:
			return {
				...state,
				exerciseTypes: {
					isDictateType: exerciseTypeCodesNext.DICTATE === exerciseType,
					isOpenEndedType: exerciseTypeCodesNext.OPEN_ENDED === exerciseType,
					isInYourMindType: exerciseTypeCodesNext.IN_YOUR_MIND === exerciseType,
					isMultipleChoiceType: exerciseTypeCodesNext.MULTIPLE_CHOICE === exerciseType,
					isTheBlanksType: exerciseTypeCodesNext.THE_BLANKS === exerciseType,
					isOpenMultipleType: exerciseTypeCodesNext.OPEN_MULTIPLE === exerciseType,
					isDictateMultipleType: exerciseTypeCodesNext.DICTATE_MULTIPLE === exerciseType,
					isMultipleChoiceMultipleAnswersType:
						exerciseTypeCodesNext.MULTIPLE_CHOICE_MULTIPLE_ANSWERS === exerciseType,
				},
			};

		case exerciseActionTypes.GET_EXERCISE_ERROR:
		case exerciseActionTypes.GET_EXERCISE_REPORT_ERROR:
			return {
				...state,
				loading: false,
			};

		case exerciseActionTypes.TOGGLE_TOPIC_INSTRUCTION:
			return {
				...state,
				showTopicInstruction,
			};

		case exerciseActionTypes.TOGGLE_QUESTION_INSTRUCTION:
			return {
				...state,
				showQuestionInstruction,
			};

		case exerciseActionTypes.SET_CURRENT_QUESTION:
			return {
				...state,
				currentQuestion,
			};

		case exerciseActionTypes.TOGGLE_INSTRUCTION_AUDIO:
			return {
				...state,
				audioOn,
			};

		case exerciseActionTypes.SET_VIEW_MODE:
			return {
				...state,
				viewMode,
			};

		case exerciseActionTypes.SEND_TOPIC_INSTRUCTION_FEEDBACK:
			return {
				...state,
				instructionFeedbacks: {
					...state.instructionFeedbacks,
					[`${instructionId}_${questionId}`]: isUseful,
				},
			};

		case exerciseActionTypes.TOGGLE_READING_MODE:
			return {
				...state,
				isReadingMode,
			};

		case exerciseActionTypes.TOGGLE_READING_TITLE:
			return {
				...state,
				showReadingTitle,
			};

		case exerciseActionTypes.TOGGLE_PULSATING_HIGHLIGHT:
			return {
				...state,
				showPulsatingHighlight,
			};

		case exerciseActionTypes.SET_READING_PROGRESS:
			return {
				...state,
				readingProgress,
			};

		case exerciseActionTypes.SET_READING_TEXT_SIZE:
			return {
				...state,
				readingTextSize,
			};

		case exerciseActionTypes.SET_SHOW_INSTRUCTION_HINT:
			return {
				...state,
				showInstructionHint,
			};

		case exerciseActionTypes.SET_INSTRUCTION_AUDIO_ON:
			return {
				...state,
				instructionAudioOn,
			};

		case exerciseActionTypes.SET_CORRECT_STREAK:
			return {
				...state,
				correctStreak,
			};

		case exerciseActionTypes.SET_ENDSCREEN:
			return {
				...state,
				endscreen,
			};

		case exerciseActionTypes.SET_LEVEL_UP:
			return {
				...state,
				levelUp,
			};

		case exerciseActionTypes.FINISH_LEARNING_PATH_SECTION_REQUEST:
			return {
				...state,
				loadingFinish: true,
			};

		case exerciseActionTypes.FINISH_LEARNING_PATH_SECTION_SUCCESS:
			return {
				...state,
				loadingFinish: false,
			};

		default:
			return state;
	}
}

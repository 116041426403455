import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import appPushNotifications from '@/components/appPushNotification/Reducer';
import errorBoundaryReducer from '@/components/errorBoundary/Reducer';
import errorHandlerReducer from '@/components/errorHandler/Reducer';
import addImageModalReducer from '@/components/modals/addImage/Reducer';
import confirmationReducer from '@/components/modals/confirmation/Reducer';
import imagePreviewReducer from '@/components/modals/imagePreview/Reducer';
import shareVideoModalReducer from '@/components/modals/shareVideo/Reducer';
import videoModalReducer from '@/components/modals/videoModal/Reducer';
import notificationReducer from '@/components/notification/Reducer';
import notificationsReducer from '@/components/notifications/Reducer';
import playButtonReducer from '@/components/playButton/Reducer';
import forgotPasswordReducer from '@/pages/forgotPassword/Reducer';
import resetPasswordReducer from '@/pages/resetPassword/Reducer';
import signInReducer from '@/pages/signIn/Reducer';
import exerciseReducer from '@/pages/exercise/Reducer';
import sharedReducer from '@/shared/Reducer';
import onboardingReducer from '@/components/onboarding/Reducer';
import reportReducer from '@/pages/report/Reducer';
import goalSettingsReducer from '@/pages/goalSettings/Reducer';
import learningPathReducer from '@/pages/learningPath/Reducer';
import dailyGoalReducer from '@/pages/dailyGoal/Reducer';
import stickersOnboardingReducer from '@/pages/stickersOnboarding/Reducer';
import difficultyModalReducer from '@/components/modals/difficulty/Reducer';
import dailyReportReducer from '@/components/dailyReport/Reducer';
import activityReducer from '@/pages/activity/Reducer';
import progressReducer from '@/pages/progress/Reducer';
import changeGradeModalReducer from '@/components/modals/changeGrade/Reducer';

const rootReducer = (history) => {
	return {
		addImageModalReducer,
		appPushNotifications,
		confirmationReducer,
		errorBoundaryReducer,
		errorHandlerReducer,
		exerciseReducer,
		forgotPasswordReducer,
		form: formReducer,
		imagePreviewReducer,
		notificationReducer,
		notificationsReducer,
		playButtonReducer,
		resetPasswordReducer,
		router: connectRouter(history),
		shareVideoModalReducer,
		videoModalReducer,
		sharedReducer,
		signInReducer,
		onboardingReducer,
		reportReducer,
		goalSettingsReducer,
		learningPathReducer,
		dailyGoalReducer,
		stickersOnboardingReducer,
		difficultyModalReducer,
		dailyReportReducer,
		activityReducer,
		progressReducer,
		changeGradeModalReducer,
	};
};

export default rootReducer;
